import {
  ConsignmentItemResponse,
  CreateOrUpdateConsignmentItemRequest,
  CreateOrUpdatePackagingRequest,
  PackagingResponse,
} from '../../../common/models'
import { AmountUnit, ConsignmentItem, PackagingType } from '../../form/schemas/consignmentItemSchema'
import {
  parseAdditionalSupplyChainActorResponse,
  toCreateOrUpdateAdditionalSupplyChainActorRequest,
} from '../useConsignment/mapper'
import { parseConsigneeConsignmentDetailResponse } from '../useConsignmentDetail/mapper'
import { excludeDeleted, parseStringToNumber } from '../../../common/utils/common-util'
import { sortBySequenceNumber } from '../../services/useFieldArrayActionHelper'

export function toCreateOrUpdatePackagingRequest(
  packaging: PackagingType,
  index: number,
): CreateOrUpdatePackagingRequest {
  return {
    id: packaging.id ?? null,
    numberOfPackages: packaging.numberOfPackages,
    sequenceNumber: index,
    shippingMarks: packaging.shippingMarks,
    typeOfPackages: packaging.typeOfPackages,
  }
}

export function toCreateOrUpdateConsignmentItemRequest(
  item: ConsignmentItem,
  houseConsignmentId: number,
): CreateOrUpdateConsignmentItemRequest {
  item.packaging.sort(sortBySequenceNumber)
  item.additionalSupplyChainActor.sort(sortBySequenceNumber)

  return {
    additionalSupplyChainActors: item.additionalSupplyChainActor
      .filter(excludeDeleted)
      .map(toCreateOrUpdateAdditionalSupplyChainActorRequest),
    combinedNomenclatureCode: item.commodityCombinedNomenclatureCode,
    consigneeId: item.consignee?.id ?? null,
    countryOfDestination: item.countryOfDestination === 'COUNTRY_' ? '' : item.countryOfDestination,
    declarationGoodsItemNumber: item.declarationGoodsItemNumber,
    descriptionOfGoods: item.commodityDescriptionOfGoods,
    grossMass: item.goodsMeasureGrossMass,
    harmonizedSystemSubHeadingCode: item.commodityHarmonizedSystemSubHeadingCode,
    id: item.id,
    netMass: item.goodsMeasureNetMass,
    packages: item.packaging
      .filter(excludeDeleted)
      .map(toCreateOrUpdatePackagingRequest),
    referenceNumberUcr: item.referenceNumberUCR,
    goodsItemNumber: item.sequenceNumber,
    houseConsignmentId,
    vatRate: (item.vatRate?.toString() ?? null),
    dutyRate: item.dutyRateUnit === 'PERCENTAGE' ? (item.dutyRate?.toString() ?? null) : null,
    dutyAmount: item.dutyRateUnit === 'AMOUNT' ? (item.dutyAmount?.toString() ?? null) : null,
    price: item.price?.toString() ?? null,
    excise: item.excise?.toString() ?? null,
  }
}

export function parsePackagingResponse(
  response: PackagingResponse | null,
  index: number,
): PackagingType {
  return {
    deleted: false,
    id: response?.id ?? null,
    numberOfPackages: response?.numberOfPackages ?? null,
    sequenceNumber: response?.sequenceNumber ?? index,
    shippingMarks: response?.shippingMarks ?? '',
    typeOfPackages: response?.typeOfPackages ?? '',
  }
}

export function parseCreateOrUpdateConsignmentItemResponse(
  response: ConsignmentItemResponse | undefined,
  index: number,
): ConsignmentItem {
  return {
    id: response?.id ?? null,
    deleted: false,
    additionalSupplyChainActor: response?.additionalSupplyChainActors?.map(parseAdditionalSupplyChainActorResponse) ?? [],
    commodityCombinedNomenclatureCode: response?.combinedNomenclatureCode ?? '',
    commodityDescriptionOfGoods: response?.descriptionOfGoods ?? '',
    commodityHarmonizedSystemSubHeadingCode: response?.harmonizedSystemSubHeadingCode ?? '',
    consignee: parseConsigneeConsignmentDetailResponse(response?.consignee),
    countryOfDestination: response?.countryOfDestination ?? '',
    declarationGoodsItemNumber: response?.declarationGoodsItemNumber ?? -1,
    goodsMeasureGrossMass: response?.grossMass ?? 0,
    goodsMeasureNetMass: response?.netMass ?? 0,
    packaging: response?.packages?.map(parsePackagingResponse) ?? [],
    referenceNumberUCR: response?.referenceNumberUcr ?? '',
    sequenceNumber: response?.goodsItemNumber ?? index,
    // additionalInformation: response?.additionalInformation.map(parseAdditionalInformationResponse),
    // additionalReference: response?.additionalReference.map(parseAdditionalReferenceResponse),
    additionalInformation: [], // E1301
    additionalReference: [], // E1301
    dangerousGoods: [], // E1301
    transportDocument: [], // Loaded separately
    previousDocument: [], // Loaded separately
    supportingDocument: [], // Loaded separately
    vatRate: parseStringToNumber(response?.vatRate) ?? null,
    dutyRate: parseStringToNumber(response?.dutyRate) ?? null,
    dutyAmount: parseStringToNumber(response?.dutyAmount) ?? null,
    dutyRateUnit: parseStringToNumber(response?.dutyAmount) != null ? AmountUnit.AMOUNT : AmountUnit.PERCENTAGE,
    price: parseStringToNumber(response?.price) ?? null,
    excise: parseStringToNumber(response?.excise) ?? null,
    initialPrice: parseStringToNumber(response?.price) ?? null,
    initialPriceCurrency: 'EUR',
  }
}
