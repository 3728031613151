import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { Dropdown } from 'react-bootstrap'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Form from 'react-bootstrap/Form'
import { useObservableState } from 'observable-hooks'
import { Observable } from 'rxjs'
import { debounceTime, switchMap } from 'rxjs/operators'
import { CarrierResponse } from '../../../common/models'
import { ContactPerson } from '../../form/schemas/commonConsignmentSchemas'
import { hasText } from '../../../common/utils/common-util'
import DropdownToggleButton from '../CommodityCodeSearch/DropdownToggleButton'
import TransitApiConfig from '../../hooks/apiConfig'

const scope = 'carrier'

function isClearEvent(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
  return event.currentTarget.value === '' && event.nativeEvent.type === 'input'
}

export type CarrierOption = {
  id: number
  carrierName: string
  identificationNumber: string
  contactPerson: ContactPerson | null
  target: 'CARRIER'
}

function mapToOption(consignmentDetailItem: CarrierResponse): CarrierOption {
  return {
    carrierName: consignmentDetailItem.carrierName ?? '',
    id: consignmentDetailItem.id ?? 0,
    identificationNumber: consignmentDetailItem.eori ?? '',
    contactPerson: {
      id: null,
      email: consignmentDetailItem.email ?? '',
      telephone: consignmentDetailItem.phone ?? '',
      name: consignmentDetailItem.name ?? '',
    },
    target: 'CARRIER',
  }
}

const {
  baseUrl,
} = TransitApiConfig.paths.consignmentDetail

function searchForOptions(inputValue: string) {
  return new Promise<CarrierResponse[]>((resolve, reject) => {
    if (inputValue === '' || inputValue === null || inputValue.trim().length < 2 || inputValue.trim().length > 35) {
      resolve([])
      return
    }

    axios.get(
      `${baseUrl}/carrier`,
      {
        params: {
          name: inputValue,
        },
      },
    ).then((consignmentDetails: AxiosResponse) => {
      resolve(consignmentDetails.data)
    }).catch((error: AxiosError) => {
      reject(error)
    })
  })
}

function CarrierSearch() {
  const { t } = useTranslation()

  const {
    setValue, getValues,
  } = useFormContext()

  const [show, setShow] = useState(false)
  const [currentSearch, setCurrentSearch] = useState<string>('')

  useEffect(() => {
    const form = getValues(scope)
    if (!hasText(form.carrierName)) return

    setCurrentSearch(form.carrierName)
  }, [getValues(scope)?.carrierName])

  const [searchResults, invokeSearch] = useObservableState(
    (event$: Observable<string>) => event$.pipe(
      debounceTime(200),
      switchMap((requestEvent) => searchForOptions(requestEvent)),
    ),
    () => ([]),
  )

  useEffect(() => {
    invokeSearch(currentSearch)
  }, [currentSearch])

  const options = useMemo<CarrierOption[]>(() => searchResults?.map(mapToOption) ?? [], [searchResults])

  return (
    <Dropdown
      as={ButtonGroup}
      className="col-12"
      show={show}
      onSelect={(eventKey) => {
        const optionMatch = options.find((option) => option.carrierName === eventKey)
        if (optionMatch) {
          setValue(scope, optionMatch)
          setCurrentSearch(optionMatch.carrierName)
          setShow(false)
        }
      }}
      onBlur={(event: React.FocusEvent<HTMLElement, HTMLAnchorElement>) => {
        if (event.relatedTarget?.classList.contains('dropdown-item')) {
          event.relatedTarget?.click()
        } else {
          setValue(`${scope}.carrierName`, currentSearch)
        }
        setShow(false)
      }}
    >
      <Form.Control
        value={currentSearch}
        type="search"
        name="carrierName"
        autoComplete="off"
        onChange={(event) => {
          if (isClearEvent(event)) {
            setCurrentSearch(event.currentTarget.value)

            return
          }
          setCurrentSearch(event.currentTarget.value)
        }}
        onFocus={() => {
          setShow(!show)
        }}
      />
      <Dropdown.Toggle
        className="no-after-caret col-2 col-lg-2 col-md-2 col-sm-1 px-2"
        onClick={() => {
          setShow(!show)
        }}
        icon={show ? 'OPEN' : 'CLOSED'}
        as={DropdownToggleButton}
      />
      <Dropdown.Menu
        className="shadow w-100 overflow-y-auto"
        align="end"
      >
        {
          (options.length === 0) && (
            <Dropdown.ItemText className="py-0">
              <span>{t('declaration.p5.typeToSearch')}</span>
            </Dropdown.ItemText>
          )
        }
        {
          options
            .map((option) => (
              <Dropdown.Item
                key={option.carrierName}
                eventKey={option.carrierName}
              >
                {option.carrierName}
              </Dropdown.Item>
            ))
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default CarrierSearch
