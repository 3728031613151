import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { removePrefix } from '../../../../common/utils/classifier-util'
import { BULGARIA, CL112_CTC_COUNTRIES } from '../../../../common/utils/ncts-constants'

const B1836Validator = {
  validateOfficeOfTransitRequirement: (formData: DeclarationForm): RuleResult => {
    if (formData.declarationType === 'TIR') {
      return 'NOT_ALLOWED'
    }

    const departureCountry = removePrefix(formData.departureCustomsOffice, 'DEPARTURE_OFFICE_', 2) ?? ''

    const destinationCountry = removePrefix(formData.destinationCustomsOffice, 'DESTINATION_OFFICE_', 2) ?? ''

    if (departureCountry === BULGARIA && destinationCountry === BULGARIA) return 'NOT_ALLOWED'

    if (CL112_CTC_COUNTRIES.includes(departureCountry)) {
      return 'REQUIRED'
    }

    if (CL112_CTC_COUNTRIES.includes(destinationCountry)) {
      return 'REQUIRED'
    }

    if (formData.declarationType === 'T2') {
      return 'REQUIRED'
    }

    return 'OPTIONAL'
  },

} as const

export default B1836Validator
